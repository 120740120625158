.form-control {
    @media only screen and (max-width: $cell-phone) {
        height: 65px !important;
        font-size: 22px !important;    
    }
}

.form-control:focus {
    border-color: rgba(255,87,87,1) !important;
    box-shadow: 0 0 0 0.15rem rgba(255,87,87,1) !important;
}
.no-border{
    border: none !important;
    padding: 0.375rem 0 !important;
    font-weight: 600 !important;
}
.invalid-feedback{
    @media only screen and (max-width: $cell-phone) {
        font-size: 19px;
        font-weight: 600;   
    }
}

.form-container-three-colums{
    display: grid;
    grid-template-columns: repeat(3,1fr);

    @media only screen and (max-width: $tablet) {
        grid-template-columns: repeat(2, 1fr);
    }
    @media only screen and (max-width: $ipad) {
        grid-template-columns: 1fr;
    }
}

.form-container-one-colum{
    display: grid;
    grid-template-columns: 1fr;
}

.form-container-two-colums{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    @media only screen and (max-width: $ipad) {
        grid-template-columns: 1fr;
    }
}


.form-container{
    &__label{
        font-weight: 600;
        @media only screen and (max-width: $cell-phone) {
            font-size: 20px;
            font-weight: 700;
        }
    }
    &__label2{
        font-weight: 600;
        margin-left: 10px;
        @media only screen and (max-width: $cell-phone) {
            font-size: 20px;
            font-weight: 700;
            margin-left: 5px;
        }
    }

    &__select{
        width: 25rem;
        padding: 20px;
        display: flex;
        flex-direction: column;
    }

    &__item{
        width: 25rem;
        padding: 20px;
    }

    &__button{
        text-align: end;
        margin: 10px;
        @media only screen and (max-width: $tablet) {
            text-align: center;
            position: relative;
            bottom: 25px;
      }
      @media only screen and (max-width: $cell-phone) {
        font-size: 22px;
    }
    }
}
.form-radio-disabled{
    color:#adb5bd !important;
}

.invalid-feedback{
    display:inline-block !important
}