@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;600&display=swap");

* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.MuiFormControl-root {
  padding: 20px !important;
}
/* All browsers */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
body {
  font-family: "Rubik", sans-serif;
  padding: 0 !important;
  overflow: auto !important;
  @media only screen and (max-width: $tablet) {
    width: fit-content;
    min-width: -webkit-fill-available;
  }
}
main {
  width: 100vw;
  padding: 20px;
  max-height: 100vh;
}

.page-container {
  min-width: 83%;
  width: 100%;
}

.project-version {
  p {
    float: right;
    padding: 10px;
    font-size: 13px;
    color: #856f6f;
    font-weight: 600;
  }
}

.page-title {
  text-align: center;
  padding: 15px;
  @media only screen and (max-width: $cell-phone) {
    font-size: 31px;
  }
}

hr {
  // width: 100%;
  // background: red;
  // height: 1px;
  margin: 0.5rem 0 !important;
}
.isOpen {
  display: flex;
  font-size: 20px;
  margin-left: 70%;
}
.containerAFH {
  display: flex;
}
.appContainer {
  width: 100%;
}
.contentAFH {
  width: 100vh;
}
.flexAFH {
  display: flex;
  min-height: 100vh;
}
.linkSidebar {
  display: flex;
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  text-decoration: none;
}
.linkIcon,
.linkName {
  font-size: 20px;
}
.active-link {
  background-color: #eb3939;
  color: #000;
  transition: all 0.5s;
}

.linkSidebar:hover {
  background-color: lightskyblue;
  color: #000;
  transition: all 0.5s;
}

/* Estilos de los submenús */
.submenu {
  color: #fff;
  padding: 10px 15px;
  gap: 15px;
  transition: all 0.5s;
  display: block;
  text-decoration: none;
  flex-direction: column;
}

.submenuItem {
  display: flex;
  gap: 10px;
}

.sidebarItem:hover .submenu {
  display: flex;
}

.submenu:hover {
  background-color: #e0d14f;
  color: #000;
  transition: all 0.5s;
}

.linkIconSubmenu {
  margin-left: 30px;
}

.linkIconSubmenu,
.linkNameSubmenu {
  font-size: 16px;
}
/* Fin Estilos de los submenús */

.brand-logo {
  @media only screen and (max-width: $cell-phone) {
    img {
      width: 113px;
      margin-left: 0;
    }
  }
}

.logo {
  font-size: 30px;
}
$dark: #212529;
.navbarAFH {
  display: none;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 100000;
}

/* Inicio user toogle */
#circular-dropdown-toggle {
  background-color: $dark;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100%;
  outline: none;
  font-size: 20px;
  width: 3rem;
  height: 3rem;
  cursor: pointer;
}

#circular-dropdown-toggle:hover {
  background-color: #eb3939;
  color: #000;
  transition: all 0.5s;
}

.circular-dropdown-menu {
  width: 200px;

  p {
    margin: 10px;
    font-weight: 500;
  }
}

.circular-dropdown-menu .dropdown-item {
  text-align: center;
  border: 1px solid #b4adad;
  border-radius: 10px;
  margin: 10px;
  width: auto;
  &:hover {
    background-color: #eb3939;
    color: #000;
    transition: all 0.3s;
    font-weight: bold;
  }
}

.dropdown-toggle::after {
  display: none;
  border: 0 !important;
}
/* Fin user toogle */
.sidebarAFH {
  background-color: $dark;
  width: 230px;
}
.sidebarAFHItem {
  background-color: $dark;
  color: #fff;
}
.topSection {
  display: flex;
  align-items: center;
  padding: 20px 15px;
  color: #fff;
}

.center-div {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swal2-container {
  z-index: 10000;
  @media only screen and (max-width: $ipad) {
    h2 {
      font-size: 18px;
    }
  }
}

.modal.show {
  z-index: 1050;
}
