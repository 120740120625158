.user-details-modal{
    &-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-item{
    display: flex;
    align-items: baseline;    
    height: 30px;
        &__subtitle{
        font-size: 16px;
        margin-right: 10px;
    }
    }
}