.crud {
  &__title {
    text-align: center;
  }
  &__title h3 {
    font-size: 1.25rem;
  }

  &__table {
    padding: 0 60px;

    &__icon-container {
      display: flex;
      justify-content: space-around;
    }

    &__icon {
      height: 22px;
      width: 17px;
      margin: 0 10px;
    }
  }

  &__rigth-buttons-container {
    text-align: right;

    button {
      margin: 10px;
    }
  }
}
