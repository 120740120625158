.operation-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(6, min-content);
    grid-column-gap: 0px;
    grid-row-gap: 0px;

    @media only screen and (max-width: $ipad) {
        display: inline;

        h5, h6{
            font-size: 15px;
        }
        p{
            font-size: 14px;
        }
        button{
            font-size: 12px;
        }
    }
}

.operation-item{
    margin: 10px;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px;
}
    
.operation-general-data { 
    grid-area: 1 / 1 / 2 / 5; 

    .header-container{
        display: flex;
        padding-bottom: 10px;
        border-bottom: 1px solid grey;

        .señado-span{   
            background-color: #e09b14;
            padding: 5px;
            margin: 2px;
            width: 100px;
            text-align: center;
            border-radius: 15px;
            color: white;
            text-transform: uppercase;

            h5{
                margin: 0;
            }
        }
    }

    .vendedor-container{
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-left: 15px;

        @media only screen and (max-width: $ipad) {
            flex-direction: column;   
        }

        p{
            margin: 0;
        }
        .factura{
            display: flex;
            align-items: baseline;
            justify-content: end;
            *{
                margin: 0 4px;
            }
        }
    }

    .cliente-container{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(4, min-content);
        padding: 10px;

        .cliente{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-area: 1 / 1 / 3 / 3;
            @media only screen and (max-width: $ipad) {
                grid-template-columns: none;  
            }
        }
        .usuario-relacionado{
            display: grid;
            grid-template-columns: repeat(3,1fr);
            grid-area: 3 / 1 / 4 / 3;
            @media only screen and (max-width: $ipad) {
                grid-template-columns: none;  
            }
        }
        .observaciones{
            grid-area: 4 / 1 / 4 / 3;
        }
        .button{
            display: flex;
            justify-content: flex-end;
            flex-direction: column;
            grid-area: 2 / 3 / 3 / 4;
        }
    }
}

.operation-total { 
    grid-area: 6 / 2 / 6 / 5;
}

.operation-pay-list { 
    grid-area: 4 / 1 / 7 / 2; 
}

.operation-product-list {
    grid-area: 2 / 1 / 4 / 5; 

    @media only screen and (max-width: $ipad) {
        th{
            font-size: 13px;
        }
        td{
            font-size: 13px;
        }
    }
}

.operation-pay-forms { 
    grid-area: 4 / 2 / 6 / 5; 

    .table-responsive{
        table{
            @media only screen and (max-width: $ipad) {
                display: grid;
                th{
                    font-size: 13px;
                }
                td{
                    font-size: 13px;
                }
            }
        }
    }

    .price-table-container{
        height: 270px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        div{
            display: flex;
            margin: 10px 0;
            align-items: baseline;
        }
    }

    .saldos{
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        @media only screen and (max-width: $ipad) {
            flex-direction: column;   
        }
        .text-container{
            display: flex;
            flex-direction: column;
        }
    }
}
.operation-buttons-container{
    display: flex;
    justify-content: space-between;
    padding: 0 10px;

    div{
        button{
            margin-right: 10px;
            color: white;
            font-weight: 600;
        }
        button:hover {
            filter: brightness(90%);
        } 
    }
}