.caja-title{
    margin: 15px 0 0 31px;
    font-weight: 700;
}

.caja-container{

    .caja-general-data{
        .vendedor-container{
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            padding-left: 15px;
            padding-bottom: 10px;
            gap: 20px;
            border-bottom: 1px solid grey;
            p{
                margin: 0;
            }
        }
    }
    .caja-saldos{
        .caja-saldo-container{
            padding: 20px;
            display: flex;
            justify-content: space-around;
            gap: 15px;
            &__item{
                margin: 0px 100px;
                display: flex;

                &__labels{
                    div{
                        display: flex;
                        align-items: baseline;
                        width: 180px;

                        h6{
                            font-weight: bold;
                        }
                    }
                }

            }
        }


    }
    .caja-ingresos{

    }
    .caja-egresos{

    }
}