.login-container{
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media only screen and (max-width: $ipad) {
        grid-template-columns: 1fr;
    }
    &__mobile-logo{
        display: none;
        text-align: center;
        background-color: black;
        @media only screen and (max-width: $ipad) {
            display: inline;
        }
        img{
            height: 100px;
            width: 236px;
            margin: 15px;
        }
        
    }
    &__box{
        background: rgb(255,7,7);
        background: linear-gradient(63deg, rgba(255,7,7,1) 0%, rgba(218,75,75,1) 49%, rgba(0,0,0,0.6895133053221288) 100%);    
        @media only screen and (max-width: $ipad) {
            display: none;
        }
    }

    &__form{
        width: 440px;
        background-color: white;
        padding: 10px;
        border-radius: 10px;
        height: min-content;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .login-title{
        width: 100%;
        text-align: center;

        div{
            background-color: black;
            padding: 10px;
            margin: 10px 0;
        }

        h5{
            text-transform: uppercase;
            color: black;
        }
    }
    &__btn-container{
        text-align: center;
        button{
            @media only screen and (max-width: $cell-phone) {
                font-size: 22px;
            }
        }
    }
} 